<script lang="ts" setup>
import { trackEvent } from '@@/bits/analytics'
import appCan from '@@/bits/app_can'
import {
  FallbackAttachmentType,
  fallbackImageUrl,
  downloadUrl as getDownloadUrl,
  getStylizedProviderName,
  urlForOpening as getUrlForOpening,
  isAttachmentBlocked,
  isUnsupportedAttachmentOnZoom,
} from '@@/bits/attachments'
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { buildUrlFromPath, navigateTo } from '@@/bits/location'
import { isTweet } from '@@/bits/post_attachment'
import { postColorRgb, serializeRgbaStringToRgba } from '@@/bits/post_color'
import { getSectionBreakoutPostPath } from '@@/bits/surface_share_links_helper'
import { NATIVE_HOST, addQueryParams, getHostname } from '@@/bits/url'
import { defineAsyncComponent } from '@@/bits/vue'
import { WHITEBOARD_ATTACHMENT_PADDING } from '@@/bits/whiteboard_no_dependencies'
import { openUrlInBrowserFromZoom } from '@@/bits/zoom'
import type { LinkType } from '@@/native_bridge/types'
import { useExpandedPostStore } from '@@/pinia/expanded_post'
import { useNativeAppStore } from '@@/pinia/native_app'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceAttachmentsStore } from '@@/pinia/surface_attachments'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfaceMediaPlayerStore } from '@@/pinia/surface_media_player'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { useSurfacePostsDragAndDropStore } from '@@/pinia/surface_posts_drag_and_drop'
import { useSurfaceShareLinksStore } from '@@/pinia/surface_share_links'
import { useWindowSizeStore } from '@@/pinia/window_size'
import { attachmentCaptionForDisplay } from '@@/surface/attachment_caption_for_display'
import type { Post, PostAttributes } from '@@/types'
import { handleLinkClick } from '@@/vuecomposables/process_post_or_comment_body'
import useZoomCollaboration from '@@/vuecomposables/zoom_collaboration'
import emitClickOnTouch from '@@/vuedirectives/emit_click_on_touch'
import type { BeethovenDisplayAttributes } from '@padlet/beethoven-client'
import { LinkHelpers } from '@padlet/beethoven-client'
import type { BeethovenContentCategory, BeethovenContentSubcategory } from '@padlet/beethoven-client/src/types'
import { isEmpty, isEqual } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { computed, nextTick, ref, watch } from 'vue'

// Do not make BeethovenAttachmentPreview async since most attachments has a preview image
import OzIconButton, {
  OzIconButtonColorScheme,
  OzIconButtonSizePreset,
} from '@@/library/v4/components/OzIconButton.vue'
import BeethovenAttachmentPreview from '@@/vuecomponents/BeethovenAttachmentPreview.vue'
import { usePreferredReducedMotion } from '@vueuse/core'

const vEmitClickOnTouch = emitClickOnTouch
const BeethovenAttachmentCaption = defineAsyncComponent(() => import('@@/vuecomponents/BeethovenAttachmentCaption.vue'))
const SurfaceAttachmentActionButton = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceAttachmentActionButton.vue'),
)
const SurfacePostAttachmentDisplayButton = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfacePostAttachmentDisplayButton.vue'),
)
const SurfaceAttachmentContentBlocked = defineAsyncComponent(
  () => import('@@/vuecomponents/SurfaceAttachmentContentBlocked.vue'),
)

const props = defineProps<{
  post: Post
  displayedPostWidth: number
  displayedPostHeight?: number
  isEditableByCurrentUser: boolean
  overrideColorScheme?: string | null
}>()

const emit = defineEmits<{
  (e: 'toggle-embed-control', value: { xEmbed: boolean; isDownloadable: boolean; downloadUrl: string }): void
  (e: 'focus-attachment'): void
  (e: 'blur-attachment'): void
  (e: 'data-loaded', data: BeethovenDisplayAttributes): void
}>()

type PostInteraction = 'expand' | 'link' | 'download' | 'padlet' | 'play'

const windowSizeStore = useWindowSizeStore()
const surfacePostsDragAndDropStore = useSurfacePostsDragAndDropStore()
const surfaceStore = useSurfaceStore()
const surfaceMediaPlayerStore = useSurfaceMediaPlayerStore()
const surfaceAttachmentsStore = useSurfaceAttachmentsStore()
const surfaceDraftsStore = useSurfaceDraftsStore()
const nativeAppStore = useNativeAppStore()
const expandedPostStore = useExpandedPostStore()
const surfacePostPropertiesStore = useSurfacePostPropertiesStore()
const surfaceShareLinksStore = useSurfaceShareLinksStore()

const { isStream, isWhiteboard, blockedContentProviders } = storeToRefs(surfaceStore)
const { shouldDisableDraggingPost } = storeToRefs(surfacePostsDragAndDropStore)
const { isSmallerThanDesktop } = storeToRefs(windowSizeStore)
const { currentlyPlayingPostCid } = storeToRefs(surfaceMediaPlayerStore)
const { isAPostDragging } = storeToRefs(surfacePostsDragAndDropStore)

/* ---------------------- */
/* STATE                  */
/* ---------------------- */
const rootEl = ref<HTMLDivElement>()
const embedWrapper = ref<HTMLDivElement>()
const attachmentCaptionEl = ref<InstanceType<typeof BeethovenAttachmentCaption>>()

const isImageLoaded = ref(false)
const isError = ref(false)
const hasBlockedContent = ref(false)
const linkData = ref<BeethovenDisplayAttributes | null>(null)
const isFallback = ref(false)

const showMeta = ref(false)
const contentCategory = ref<BeethovenContentCategory | null>(null)
const contentSubcategory = ref<BeethovenContentSubcategory | null>(null)
const attachmentAspectRatio = ref(0)
const attachmentHeight = ref(0)
const attachmentColor = ref('rgb(0,0,0)')
const providerName = ref('')
const isDownloadable = ref(false)
const attachmentEmbedCode = ref('')
const errorText = ref<string | null>(null)
const attachmentLuminance = ref()
const downloadFileName = ref<string | null>(null)
const embedHtml = ref<string | null>(null)
/* ---------------------- */
/* GETTERS - DATA LOADING */
/* ---------------------- */
const lowercaseProviderName = computed(() => providerName.value.toLowerCase())
const interaction = computed<PostInteraction>(() => {
  if (isTweet(linkData.value?.metadata?.attachment_props)) {
    // Ensure we never show the Tweet embed when using the new Tweet preview,
    // instead, we take the users to the tweet on Twitter.
    return 'link'
  }

  // Return link if it is a zoom app and it is a non-youtube video
  if (device.zoomApp && contentSubcategory.value === 'padlet') {
    return 'padlet'
  }

  if (isUnsupportedAttachmentOnZoom(contentCategory.value, providerName.value)) {
    return 'link'
  }

  if (isWhiteboard.value && (contentCategory.value === 'video' || contentCategory.value === 'audio')) {
    return 'play'
  }

  if (contentCategory.value === 'audio' && lowercaseProviderName.value !== 'padlet drive' && !isStream.value) {
    return 'expand'
  }
  if (lowercaseProviderName.value === 'tiktok') {
    return 'expand'
  }
  if (contentCategory.value === 'audio' || (!isAppUsing('playVideoInExpanded') && contentCategory.value === 'video')) {
    return 'play'
  }
  if (isDownloadable.value && contentCategory.value === 'file') {
    return 'download'
  }

  if (contentCategory.value === 'photo' || (isAppUsing('playVideoInExpanded') && contentCategory.value === 'video')) {
    return 'expand'
  }
  if (contentCategory.value === 'page') {
    return 'link'
  }
  if (attachmentEmbedCode.value && contentSubcategory.value !== 'padlet') {
    return 'expand'
  }

  return 'link'
})

// #region /** Accessibility */
interface PostAriaDetails {
  description: string
  isDescriptionGeneratedFromAttachment: boolean
}

const getPostAriaDetails = (
  post: PostAttributes,
  attachmentDisplayAttributes?: BeethovenDisplayAttributes | null,
): PostAriaDetails => {
  let description = ''
  let isDescriptionGeneratedFromAttachment = true

  if (!isEmpty(post.subject)) {
    isDescriptionGeneratedFromAttachment = false
    description = post.subject as string
    return {
      description,
      isDescriptionGeneratedFromAttachment,
    }
  }
  if (!isEmpty(post.wish_content?.attachment_props?.alternative_text)) {
    description = post.wish_content?.attachment_props?.alternative_text as string
    return {
      description,
      isDescriptionGeneratedFromAttachment,
    }
  }
  if (!isEmpty(post.attachment_caption)) {
    description = post.attachment_caption as string
    return {
      description,
      isDescriptionGeneratedFromAttachment,
    }
  }
  if (description === '' && attachmentDisplayAttributes != null) {
    description = attachmentCaptionForDisplay({
      attachmentCaption: post.attachment_caption,
      attachmentDisplayAttributes,
    })
  }

  return {
    description,
    isDescriptionGeneratedFromAttachment,
  }
}

const getPostAttachmentAnchorAriaLabel = ({
  post,
  interaction,
  attachmentDisplayAttributes,
}: {
  post: PostAttributes
  interaction: PostInteraction
  attachmentDisplayAttributes?: BeethovenDisplayAttributes | null
}): string => {
  if (interaction === 'link') {
    // we want the link to be read as is
    return ''
  }

  if (interaction === 'download') {
    return __('Download %{fileDescription}', {
      fileDescription: `${attachmentDisplayAttributes?.content_subcategory ?? ''} ${
        attachmentDisplayAttributes?.content_category ?? ''
      }`.trim(),
    })
  }

  const { description, isDescriptionGeneratedFromAttachment } = getPostAriaDetails(post, attachmentDisplayAttributes)
  return isDescriptionGeneratedFromAttachment
    ? __('Open the post with attachment of %{description} in expanded view', {
        description,
      })
    : __('Open the post %{description} in expanded view', {
        description,
      })
}
const ariaLabel = computed(() =>
  getPostAttachmentAnchorAriaLabel({
    post: props.post,
    interaction: interaction.value,
    attachmentDisplayAttributes: linkData.value,
  }),
)

// #endregion

const isDataLoaded = computed(() => {
  // We show the loading placeholder until we have `linkData` emitted from `BeethovenAttachmentPreview`.
  // For tweets, we don't rely on this `linkData` but instead we use `post.wish_content.attachment_props`.
  // See the comment in `BeethovenAttachmentPreview.vue`'s `getBeethovenAttributes` method for more details.
  return (
    (!isError.value && !!linkData.value) || (!isWhiteboard.value && isTweet(props.post.wish_content?.attachment_props))
  )
})

const customThumbnailUrl = computed(() => props.post.wish_content?.attachment_props?.custom_thumbnail_url)

const urlForDisplay = computed(() => {
  if (isTweet(props.post.wish_content?.attachment_props)) {
    if (isWhiteboard.value) {
      return linkData.value?.url
    }
    return props.post.wish_content?.attachment_props.url as string
  }
  if (linkData.value?.url) return linkData.value?.url
  if (isError.value) return url.value

  return undefined
})

const downloadUrl = computed(() => {
  if (urlForDisplay.value) {
    return getDownloadUrl(urlForDisplay.value)
  }
  return ''
})

const urlForOpening = computed(() => {
  // If attachment should be opened in expanded view, we set post permalink
  // as the URL so that cmd/ctrl clicking opens it in a new tab.
  if (interaction.value === 'expand' && props.post.permalink) {
    if (surfaceStore.isSectionBreakout && props.post.id) {
      return buildUrlFromPath(
        getSectionBreakoutPostPath(
          surfaceStore.namespace,
          surfaceShareLinksStore.sectionBreakoutIdentifier ?? '',
          props.post.id,
        ),
      )
    }
    return props.post.permalink
  }
  return getUrlForOpening(linkData.value, urlForDisplay.value)
})

const url = computed(() => props.post.attachment)
const hasCaption = computed(() => !!attachmentCaption.value)
const buttonIcon = computed(() => {
  if (isUnsupportedAttachmentOnZoom(contentCategory.value, providerName.value)) {
    return null
  }

  if (contentCategory.value === 'video' || contentCategory.value === 'audio') {
    return 'play'
  }

  return null
})
const attachmentCaption = computed(() =>
  attachmentCaptionForDisplay({
    attachmentCaption: props.post.attachment_caption,
    attachmentDisplayAttributes: linkData.value,
  }),
)

// #region /** GIF handling */
const prefersReducedMotion = usePreferredReducedMotion()
const isGif = computed(() => contentSubcategory.value === 'gif')
const isGifPaused = ref(false)
watch(
  isGif,
  () => {
    if (isGif.value) {
      isGifPaused.value = prefersReducedMotion.value === 'reduce'
    }
  },
  {
    immediate: true,
  },
)
const handleGifButtonClick = () => {
  isGifPaused.value = !isGifPaused.value
}

// #endregion

/* ---------------------- */
/* GETTERS - STYLING      */
/* ---------------------- */
const colorScheme = computed(() => props.overrideColorScheme ?? surfaceStore.colorScheme)
const isLightColorScheme = computed(() => colorScheme.value === 'light')
const displayedPostColor = computed(() => surfacePostPropertiesStore.getDisplayedColorForPost(props.post))
const backgroundColor = computed(() =>
  postColorRgb({ postColor: displayedPostColor.value, isLightColorScheme: isLightColorScheme.value }),
)
const imageBackgroundColor = computed(() => {
  if (!attachmentColor.value) return backgroundColor.value
  return serializeRgbaStringToRgba(attachmentColor.value)
})
const imageBackgroundColorString = computed(() => {
  const c = imageBackgroundColor.value
  return `rgb(${c.r},${c.g},${c.b})`
})

const placeholderColorClass = computed(() => {
  if (isDataLoaded.value) {
    return ''
  }
  // pulse between post background color and 3 shade darker in light mode
  // pulse between post background color and 2 shade lighter in dark mode
  switch (displayedPostColor.value) {
    case 'red':
      return isLightColorScheme.value
        ? 'animate-pulse-bg-color-slow pulse-bg-from-scarlet-100 pulse-bg-to-scarlet-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-scarlet-900 pulse-bg-to-scarlet-700'
    case 'orange':
      return isLightColorScheme.value
        ? 'animate-pulse-bg-color-slow pulse-bg-from-canary-100 pulse-bg-to-canary-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-tangerine-900 pulse-bg-to-tangerine-700'
    case 'green':
      return isLightColorScheme.value
        ? 'animate-pulse-bg-color-slow pulse-bg-from-park-100 pulse-bg-to-park-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-park-900 pulse-bg-to-park-700'
    case 'blue':
      return isLightColorScheme.value
        ? 'animate-pulse-bg-color-slow pulse-bg-from-oceanic-100 pulse-bg-to-oceanic-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-oceanic-900 pulse-bg-to-oceanic-700'
    case 'purple':
      return isLightColorScheme.value
        ? 'animate-pulse-bg-color-slow pulse-bg-from-grape-100 pulse-bg-to-grape-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-grape-900 pulse-bg-to-grape-700'
    default:
      return isLightColorScheme.value
        ? 'animate-pulse-bg-color-slow pulse-bg-from-light-ui-100 pulse-bg-to-light-ui-400'
        : 'animate-pulse-bg-color-slow pulse-bg-from-dark-ui-100 pulse-bg-to-dark-ui-400'
  }
})

const realAttachmentHeight = computed(() => {
  if (isWhiteboard.value) {
    return props.displayedPostHeight - WHITEBOARD_ATTACHMENT_PADDING * 2
  }
  return attachmentHeight.value || attachmentWidth.value / attachmentAspectRatio.value
})
const attachmentWidth = computed(() => {
  // Attachment have padding of 8px on each side, increased to 12px for stream, and no padding for whiteboard
  if (isStream.value) {
    return props.displayedPostWidth - 24
  }
  if (isWhiteboard.value) {
    return props.displayedPostWidth - WHITEBOARD_ATTACHMENT_PADDING * 2
  }
  return props.displayedPostWidth - 16
})

/* ---------------------- */
/* GETTERS - EMBEDS       */
/* ---------------------- */
const isPadletAttachment = computed(() => contentSubcategory.value === 'padlet' && providerName.value !== 'padlet')
const showEmbed = computed(() => surfaceMediaPlayerStore.isPostPlayingMedia(props.post.cid))

/* ----------------------  */
/* WATCHERS - DATA LOADING */
/* ----------------------  */
watch(url, () => {
  isError.value = false
  isImageLoaded.value = false
  linkData.value = null
  isFallback.value = false
  showMeta.value = false
})
/* ----------------------  */
/* WATCHERS - EMBEDS       */
/* ----------------------  */
watch(backgroundColor, (newVal, oldVal) => {
  // Dec 6 2022: We added this deep comparison check to prevent calling `embedInline` if the RGB
  // values haven't changed. The watcher can be triggered when we toggle the settings panel, which
  // is a weird bug because none of `backgroundColor` dependencies change (`this.displayedPostColor` and `this.isLightColorScheme`)
  // but the composed object does change (different reference, same value). That's why we need a deep comparison.
  // Additional note: the getter value is only recomputed when we add the watcher.
  const bgColorHasChanged = !isEqual(newVal, oldVal)
  if (bgColorHasChanged && showEmbed.value && !!linkData.value && LinkHelpers.isAudio(linkData.value)) {
    embedInline()
  }
})

watch(showEmbed, () => {
  emit('toggle-embed-control', {
    xEmbed: showEmbed.value,
    isDownloadable: isDownloadable.value,
    downloadUrl: downloadUrl.value,
  })
})

watch(currentlyPlayingPostCid, (newCid?: string | null) => {
  if (showEmbed.value && newCid && props.post.cid !== newCid) {
    backToPreview()
  }
})
/* ---------------------- */
/* ACTIONS - DATA LOADING */
/* ---------------------- */
const handleDataLoaded = (data: BeethovenDisplayAttributes) => {
  errorText.value = null
  isError.value = false
  hasBlockedContent.value = isAttachmentBlocked(data, blockedContentProviders.value)
  linkData.value = data
  isFallback.value = data.is_fallback
  contentCategory.value = data.content_category
  contentSubcategory.value = data.content_subcategory
  attachmentEmbedCode.value = data.embed_code
  attachmentAspectRatio.value = data.image_aspect_ratio
  attachmentHeight.value = data.image_height
  attachmentColor.value = data.image_color
  attachmentLuminance.value = data.image_luminance
  isDownloadable.value = data.is_downloadable && !device.mobile
  showMeta.value = data.show_meta
  providerName.value = getStylizedProviderName(data.provider_name)

  if (contentCategory.value === 'file') {
    downloadFileName.value = props.post.subject || null
  }
  emit('data-loaded', data)
}

const handleImageLoaded = () => {
  isImageLoaded.value = true
  if (url.value) {
    surfaceAttachmentsStore.setSurfacePreviewImageLoaded({ url: url.value })
  }
}

const handleBeethovenError = (error: string) => {
  isError.value = true
  errorText.value = error
}
/* ---------------------- */
/* ACTIONS - EMBEDS       */
/* ---------------------- */
const embedInline = () => {
  surfaceMediaPlayerStore.setCurrentlyPlayingMedia({ type: 'post', id: props.post.cid })
  if (hasBlockedContent.value) {
    return
  }

  embedHtml.value = attachmentEmbedCode.value
  nextTick(() => {
    const embedTag = embedWrapper.value?.firstChild as HTMLElement | null
    if (embedTag && embedTag.tagName.toLowerCase() === 'iframe') {
      const embedTagIframe = embedTag as HTMLIFrameElement
      // Set loading attributes: width, height and underlay background while it's loading
      let embedWidth = attachmentWidth.value
      let embedHeight = realAttachmentHeight.value
      // For YouTube videos, we use the width & height from its embed code instead as
      // they are more accurate
      if (lowercaseProviderName.value === 'youtube') {
        const ytEmbedWidth = embedTagIframe.getAttribute('width')
        const ytEmbedHeight = embedTagIframe.getAttribute('height')
        const ytEmbedAspectRatio = Number(ytEmbedWidth) / Number(ytEmbedHeight)
        if (!Number.isNaN(ytEmbedAspectRatio)) {
          embedWidth = attachmentWidth.value
          embedHeight = Math.round(attachmentWidth.value / ytEmbedAspectRatio)
        }
      }
      embedTagIframe.setAttribute('width', embedWidth + 'px')
      embedTagIframe.setAttribute('height', embedHeight + 'px')
      embedTagIframe.style.background = attachmentColor.value

      // Override iframe for better behavior
      const currentSrc = embedTagIframe.getAttribute('src')
      let newSrc = addQueryParams(currentSrc, 'autoplay=1')

      if (!!linkData.value && LinkHelpers.isAudio(linkData.value)) {
        const fallbackImgSrc = fallbackImageUrl(
          FallbackAttachmentType.Audio,
          colorScheme.value,
          displayedPostColor.value,
        )
        newSrc = addQueryParams(newSrc, `poster_url=${fallbackImgSrc}`)
      }

      // Need to set this for cross-origin autoplay to work, e.g. padlet.com/beethoven/play on starkindustries.padlet.org
      if (lowercaseProviderName.value === 'padlet drive') {
        embedTagIframe.setAttribute('allow', 'autoplay')
      }
      if (lowercaseProviderName.value === 'youtube') {
        newSrc = addQueryParams(newSrc, 'rel=0')
      }
      embedTagIframe.setAttribute('src', newSrc)
      embedHtml.value = embedTagIframe.outerHTML
    }
  })
}
/* ---------------------- */
/* ACTIONS - PLAYING      */
/* ---------------------- */
const { isZoomCollaborationGuest } = useZoomCollaboration()

const play = (e: MouseEvent): void => {
  if (rootEl.value?.closest('.surface-post')?.classList.contains('noclick')) {
    // Prevent play events when the post is being dragged
    e.preventDefault()
    return
  }

  if ((device.mac && e.metaKey) || (!device.mac && e.ctrlKey)) {
    // Prevent play events when cmd/ctrl clicking
    if (isAppUsing('linkSafetyModal')) {
      e.preventDefault()
      handleLinkClick(urlForOpening.value, props.post.author_hashid)
    }
    return
  }

  if (device.zoomApp && interaction.value === 'link') {
    // Prevent play events when in Zoom app
    e.preventDefault()
    openUrlInBrowserFromZoom(urlForOpening.value)
    return
  }

  if (device.zoomApp && interaction.value === 'padlet') {
    // Open padlets within the same panel
    e.preventDefault()
    isZoomCollaborationGuest.value && getHostname(urlForOpening.value) === NATIVE_HOST
      ? openUrlInBrowserFromZoom(urlForOpening.value)
      : navigateTo(urlForOpening.value)
    return
  }

  if (interaction.value === 'play') {
    // opening link: content is video and audio so we display player
    embedInline()
    if (contentCategory.value === 'video') {
      trackEvent('Attachment', 'Played video inline', null, null, { wishId: props.post.id })
    }
    if (contentCategory.value === 'audio') {
      trackEvent('Attachment', 'Played audio inline', null, null, { wishId: props.post.id })
    }
    e.preventDefault()
    return
  }

  // for older clients, even if interaction is expand, we just play the attachment in native app
  if (device.app && appCan('postMessage') && !appCan('nativeExpandedPostV1')) {
    playInNativeApp()
    return
  }

  if (interaction.value === 'expand') {
    // expand post with text panel closed
    expandedPostStore.expandPost({ postCid: props.post.cid, xTextPanel: !isSmallerThanDesktop.value })
    e.preventDefault()
    trackEvent('Surface', 'Expanded post', null, null, { wishId: props.post.id })
    return
  }

  if (interaction.value === 'link' && isAppUsing('linkSafetyModal')) {
    // opening link: content is page or document so we open in new tab
    e.preventDefault()
    handleLinkClick(urlForOpening.value, props.post.author_hashid)
    return
  }

  if (device.app && appCan('postMessage')) {
    // opening link priority moved to after expand: isApp so use postMessage to open link
    playInNativeApp()
  }
}
/* ---------------------- */
/* ACTIONS - NATIVE APP   */
/* ---------------------- */
const playInNativeApp = () => {
  let linkType: LinkType = 'external'
  if (contentSubcategory.value === 'padlet' && isPadletAttachment.value) {
    linkType = 'padlet'
  } else if (contentCategory.value === 'photo' || contentCategory.value === 'file') {
    linkType = 'internal_mediaplayer'
  } else if (contentCategory.value === 'page' || contentCategory.value === 'document') {
    linkType = 'internal_webview'
  }

  if (urlForDisplay.value) {
    nativeAppStore.openLink({
      url: urlForDisplay.value,
      linkType,
      contentCategory: contentCategory.value ?? undefined,
      contentSubcategory: contentSubcategory.value ?? undefined,
    })
  }
}

/* ---------------------- */
/* ACTIONS - MISC         */
/* ---------------------- */
const openPostModal = () => {
  if (props.isEditableByCurrentUser) {
    surfaceDraftsStore.startEditingDraft(props.post.cid)
  }
}

/* -------------------------------------------------------- */
/* ACTIONS - Used by exposed function `postMessage`         */
/* -------------------------------------------------------- */
const backToPreview = () => {
  surfaceMediaPlayerStore.unsetCurrentlyPlayingMedia()
}

const openInNew = () => {
  if (urlForDisplay.value) {
    if (device.app) {
      nativeAppStore.openLink({
        url: urlForDisplay.value,
        linkType: 'external',
        contentCategory: contentCategory.value ?? undefined,
        contentSubcategory: contentSubcategory.value ?? undefined,
      })
    } else {
      navigateTo(urlForDisplay.value, { target: '_blank' })
    }
  }
}

/* ---------------------- */
/* EXPOSED FUNCTIONS      */
/* ---------------------- */
// Used by SurfacePostBeingShowed.vue
export type AttachmentPostMessageEvent = 'back-to-preview' | 'open-in-new'
const postMessage = (event: AttachmentPostMessageEvent): void => {
  switch (event) {
    case 'back-to-preview':
      backToPreview()
      break
    case 'open-in-new':
      backToPreview()
      openInNew()
  }
}

defineExpose({
  postMessage,
  embedWrapper,
  play,
  interaction,
})
</script>

<template>
  <div ref="rootEl" class="relative group">
    <!-- 1. nothing loaded - display placeholder element with default height/background color  -->
    <!-- 2. data loaded - update placeholder element with height/color of preview image -->
    <!-- 3. image loaded - show preview img itself -->
    <!-- 4. gif paused -->
    <div
      v-if="!isImageLoaded || isGifPaused"
      :class="['rounded-lg', placeholderColorClass, 'flex-1']"
      :style="{
        width: `${attachmentWidth}px`,
        height: `${isDataLoaded ? attachmentHeight : attachmentWidth / 2}px`,
        backgroundColor: isDataLoaded ? imageBackgroundColorString : undefined,
        willChange: 'background-color',
      }"
    />
    <a
      v-show="!showEmbed && isImageLoaded"
      v-emit-click-on-touch
      data-testid="showAttachmentPreview"
      :data-testid-is-loaded="isDataLoaded"
      class="relative flex flex-1 group"
      :href="urlForOpening"
      :aria-label="ariaLabel"
      target="_blank"
      rel="noopener nofollow ugc"
      :draggable="!shouldDisableDraggingPost"
      @focus="emit('focus-attachment')"
      @blur="emit('blur-attachment')"
      @click="play"
      @mousedown.prevent
    >
      <!-- N.b. Set key so that when the attachment changes, the attachment is reloaded -->
      <BeethovenAttachmentPreview
        v-if="!isGifPaused"
        :key="url"
        :class="[
          'flex-auto w-full',
          placeholderColorClass,
          'group-focus-visible:ring-2 rounded-lg',
          {
            'group-focus-visible:ring-canary border-dark-text-100': colorScheme === 'dark',
            'group-focus-visible:ring-grape border-light-text-100': colorScheme === 'light',
          },
        ]"
        :style="{ willChange: 'background-color' }"
        border-radius-class="rounded-lg"
        :url="url"
        :width="attachmentWidth"
        :x-border="false"
        :dark-theme-type="'custom'"
        :is-dark="colorScheme === 'dark'"
        :background-color="displayedPostColor"
        :usage-context="'postView'"
        :post-cid="post.cid"
        :lazy-loading="false"
        fetch-priority="high"
        draggable="false"
        :x-hover-overlay="!isAPostDragging"
        :custom-thumbnail-url="customThumbnailUrl"
        @load-data="handleDataLoaded"
        @load-image="handleImageLoaded"
        @error="handleBeethovenError"
      >
        <template #adornment>
          <slot name="adornment" />
        </template>
      </BeethovenAttachmentPreview>
      <SurfaceAttachmentActionButton
        v-if="buttonIcon && isWhiteboard"
        :icon-name="buttonIcon"
        :title="buttonIcon === 'play' ? __('Play attachment') : __('Download attachment')"
        :aria-label="buttonIcon === 'play' ? __('Play attachment') : __('Download attachment')"
        :post-color="displayedPostColor"
        :color-scheme="colorScheme"
        :size="isWhiteboard ? '64px' : '32px'"
        @click.prevent.stop="play"
      />
      <SurfacePostAttachmentDisplayButton
        v-else-if="buttonIcon"
        :post="post"
        :color="displayedPostColor"
        :icon-name="buttonIcon"
      />

      <!-- no more attachment bar -->
      <!-- no more content picker -->
    </a>
    <OzIconButton
      v-if="isGif"
      :class="[
        'absolute bottom-1 end-1 z-10',
        isGifPaused ? 'opacity-100' : 'opacity-0',
        'focus:opacity-100',
        'transition-opacity',
        'group-hover:opacity-100',
      ]"
      :dark-mode="attachmentLuminance === 'dark'"
      :label="isGifPaused ? __('Play GIF') : __('Pause GIF')"
      :color-scheme="OzIconButtonColorScheme.SecondaryClear"
      :size-preset="OzIconButtonSizePreset.H24px"
      :icon-name="isGifPaused ? 'play' : 'pause'"
      @click="handleGifButtonClick"
    />

    <div v-if="showEmbed">
      <div
        v-if="hasBlockedContent"
        class="flex overflow-hidden rounded-lg pointer-events-auto surface-post-attachment-inline-embed"
      >
        <SurfaceAttachmentContentBlocked
          :provider-name="lowercaseProviderName"
          :style="{
            width: `${attachmentWidth}px`,
            height: `${attachmentHeight}px`,
          }"
        />
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-else
        ref="embedWrapper"
        :style="{ backgroundColor: imageBackgroundColorString }"
        class="flex overflow-hidden rounded-lg pointer-events-auto surface-post-attachment-inline-embed"
        v-html="embedHtml"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <BeethovenAttachmentCaption
      v-if="!isWhiteboard && hasCaption"
      ref="attachmentCaptionEl"
      :class="{ 'px-1': true, 'mt-2': isStream, 'mt-1.5': !isStream }"
      :x-placeholder="isEditableByCurrentUser"
      :attachment-caption="attachmentCaption"
      :dark-theme-type="'custom'"
      :is-dark="colorScheme === 'dark'"
      :usage-context="'postView'"
      @edit-start="openPostModal"
    />
    <div
      v-if="isError"
      data-testid="attachmentPreviewErrorMessage"
      :class="{
        'mx-1 mt-1.5 mb-0.5': true,
        'text-body-extra-small': !isWhiteboard,
        'text-whiteboard-body-extra-small': isWhiteboard,
        italic: true,
        'text-dark-text-200': colorScheme === 'light',
        'text-light-text-200': colorScheme === 'dark',
      }"
    >
      {{ __('Sorry, we could not get a preview for this attachment.') }}
    </div>
  </div>
</template>
